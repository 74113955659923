<script>
import {
  createProductPreOrder,
  getProductPreOrders,
  removeProductPreOrder,
} from "@/api/product";
import { getCities } from "@/api/branch";

export default {
  data() {
    return {
      loading: false,
      pre_orders: [],
      cities: [],

      form: {
        city_id: null,
        pre_order: 0,
      },
    };
  },
  computed: {
    product_id() {
      return this.$route.params.id;
    },
  },
  async mounted() {
    await this.getProductPreOrders();
    getCities().then((res) => {
      this.cities = res;
      console.log(res);
    });
  },
  methods: {
    async getProductPreOrders() {
      try {
        this.loading = true;
        const result = await getProductPreOrders(this.product_id);
        console.log(result);
        if (result.success) {
          this.pre_orders = result.pre_orders;
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
      this.loading = false;
    },
    getCityName(cityId) {
      const city = this.cities.find((c) => c.id === cityId);
      return (city && city.name) || cityId;
    },
    async addPreOrder() {
      if (!this.form.city_id || !this.form.pre_order) return;

      this.loading = true;
      const res = await createProductPreOrder({
        product_id: this.product_id,
        city_id: this.form.city_id,
        pre_order: this.form.pre_order,
      });

      this.loading = false;

      if (res.success) {
        await this.getProductPreOrders();
      } else {
        this.$message.error(`Ошибка: Уже есть в базе такой предзаказ`);
      }
    },

    async removePreOrder(cityId) {
      this.loading = true;
      const res = await removeProductPreOrder({
        product_id: this.product_id,
        city_id: cityId,
      });

      this.loading = false;

      if (res.success) {
        await this.getProductPreOrders();
      } else {
        this.$message.error(`Ошибка: Не удалось удалить`);
      }
    },
  },
};
</script>

<template>
  <div>
    <h1 class="font-semibold text-xl mt-5 mb-2 flex justify-between flex-col">
      <div class="mb-3">Предзаказы</div>
      <div class="flex">
        <el-select v-model="form.city_id" placeholder="Город" size="small">
          <el-option
            v-for="c in cities"
            :key="c.id"
            :label="c.name"
            :value="c.id"
          ></el-option>
        </el-select>
        <el-input-number
          v-model="form.pre_order"
          type="number"
          placeholder="Предзаказ"
          size="small"
          class="mr-2 ml-2"
        ></el-input-number>
        <el-button
          @click="addPreOrder"
          :disabled="loading"
          icon="el-icon-plus"
          plain
          round
          type="primary"
          size="small"
        >
          Добавить
        </el-button>
      </div>
    </h1>

    <el-table border size="small" :data="pre_orders">
      <el-table-column type="index" label="N"></el-table-column>
      <el-table-column prop="city_id" label="Город">
        <template slot-scope="scope">
          {{ getCityName(scope.row.city_id) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="pre_order"
        label="Предзаказ"
        align="right"
      ></el-table-column>
      <el-table-column align="right" prop="brand" label="Действия">
        <template slot-scope="scope">
          <el-button
            @click.stop="removePreOrder(scope.row.city_id)"
            :disabled="loading"
            size="small"
            plain
            icon="el-icon-delete"
            type="danger"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<style scoped></style>
