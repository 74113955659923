export const getOrderStatus = (acc) => {
  if (acc.type === "income") {
    return {
      label: "Приход",
      type: "primary",
    };
  }
  if (acc.accounting_status === -1) {
    return {
      label: "Отменен",
      type: "danger",
    };
  }
  if ([1, 2, 5].includes(acc.channel_id)) {
    if (acc.order_state === "ARCHIVE") {
      if (acc.order_status === "COMPLETED")
        return {
          label: "Уход",
          type: "success",
        };
      if (
        ["CANCELED_BY_CUSTOMER", "CANCELED_BY_MERCHANT", "CANCELLED"].includes(
          acc.order_status
        )
      )
        return {
          label: "Отменен",
          type: "danger",
        };
      if (acc.order_status === "RETURNED")
        return {
          label: "Возврат",
          type: "warning",
        };
    }

    if (!acc.order_state && !acc.order_status) {
      return {
        label: "Уход",
        type: "success",
      };
    }

    return {
      label: "Бронь",
      type: "warning",
    };
  }
  return {
    label: "Уход",
    type: "success",
  };
};
