<template>
  <div>
    <h1 class="font-semibold text-xl mb-5 flex justify-between items-center">
      <div>
        {{ (product && product.name) || "Создать продукт" }}
        <i v-if="loading" class="el-icon-loading"></i>
      </div>
      <div>
        <el-button
          @click="() => $router.back()"
          size="small"
          icon="el-icon-back"
          round
        >
          Назад
        </el-button>
        <el-button
          @click="deleteProduct"
          v-if="product_id !== 'new'"
          size="small"
          plain
          round
          type="danger"
        >
          Удалить
        </el-button>
        <el-button plain round type="primary" size="small" @click="saveProduct">
          Сохранить
        </el-button>
      </div>
    </h1>

    <el-row>
      <el-col :span="10">
        <el-form label-position="top">
          <div class="mb-5 rounded-md">
            <div class="mb-3">
              <p class="text-gray-500 mb-1">Магазин</p>
              <el-select v-model="product.shop_id" filterable class="w-full">
                <el-option
                  v-for="c in shops"
                  :key="c.id"
                  :value="c.id"
                  :label="c.company"
                ></el-option>
              </el-select>
            </div>

            <div class="mb-3">
              <p class="text-gray-500 mb-1">Только с этого пункта</p>
              <el-input clearable v-model="product.pp"></el-input>
            </div>

            <div class="mb-3">
              <p class="text-gray-500 mb-1">Снято с продажи</p>
              <el-checkbox
                v-model="product.status"
                :true-label="-1"
                :false-label="0"
              ></el-checkbox>
            </div>
            <el-row :gutter="20">
              <el-col :span="16">
                <div class="mb-3">
                  <p class="text-gray-500 mb-1">Категория</p>
                  <el-select
                    v-model="product.category_id"
                    filterable
                    class="w-full"
                  >
                    <el-option
                      v-for="c in categories"
                      :key="c.id"
                      :value="c.id"
                      :label="c.name"
                    ></el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="mb-3">
                  <p class="text-gray-500 mb-1">Бренд</p>
                  <el-input v-model="product.brand"></el-input>
                </div>
              </el-col>
            </el-row>

            <div class="mb-3">
              <p class="text-gray-500 mb-1">Название</p>
              <el-input v-model="product.name"></el-input>
            </div>

            <div class="mb-3">
              <p class="text-gray-500 mb-1">Поставщик работает в субботу</p>
              <el-checkbox v-model="product.saturday"></el-checkbox>
            </div>

            <el-row :gutter="20">
              <el-col :span="12">
                <div class="mb-3">
                  <p class="text-gray-500 mb-1">Гарантия</p>
                  <el-input v-model="product.guarantee_period"></el-input>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="mb-3">
                  <p class="text-gray-500 mb-1">Средняя цена</p>
                  <el-input v-model="average_price" disabled></el-input>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-col>
      <el-col :span="12" :offset="2">
        <template v-if="product_id !== 'new'">
          <product-modificators />

          <pre-order-settings></pre-order-settings>

          <h1
            class="font-semibold text-xl mt-5 mb-2 flex justify-between items-center"
          >
            <div>Доступность</div>
          </h1>
          <el-table border size="small" :data="storage">
            <el-table-column type="index" label="N"></el-table-column>
            <el-table-column
              prop="warehouse_name"
              label="Склад"
            ></el-table-column>
            <el-table-column prop="count" label="Кол-во"></el-table-column>
            <el-table-column
              prop="pre_order"
              label="Предзаказ"
            ></el-table-column>
          </el-table>

          <h1
            class="font-semibold text-xl mt-5 mb-2 flex justify-between items-center"
          >
            <div>Уходы и приходы</div>
          </h1>
          <el-table border size="small" :data="accountings">
            <el-table-column type="index" label="N"></el-table-column>
            <el-table-column prop="created_at" label="Документ">
              <template v-slot="scope">
                <router-link
                  target="_blank"
                  class="text-blue-500"
                  :to="{
                    name: 'accounting-item',
                    params: { id: scope.row.id },
                  }"
                >
                  {{ scope.row.basis }}
                </router-link>
              </template>
            </el-table-column>
            <el-table-column
              prop="warehouse_name"
              label="Склад"
            ></el-table-column>
            <el-table-column prop="created_at" label="Создано">
              <template v-slot="scope">
                {{ dateTimeFormat(scope.row.created_at2) }}
              </template>
            </el-table-column>
            <el-table-column prop="total_price" label="Сумма">
            </el-table-column>
            <el-table-column prop="order_status" label="Статус">
              <template slot-scope="scope">
                {{ scope.row.order_state }} <br />
                {{ scope.row.order_status }}
              </template>
            </el-table-column>
            <el-table-column prop="type" label="Тип">
              <template v-slot="scope">
                <i
                  v-if="
                    problem_accounting_ids.includes(scope.row.id) ||
                    scope.row.pre_order > 0
                  "
                  class="el-icon-warning text-red-500 mr-2"
                ></i>

                <el-tag
                  size="mini"
                  :type="getStateOfAccounting(scope.row).type"
                >
                  {{ getStateOfAccounting(scope.row).label }}
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  getProduct,
  createProduct,
  updateProduct,
  deleteProduct,
} from "@/api/product";
import { getShops } from "@/api/branch";
import { getCategories } from "@/api/category";
import { dateTimeFormat } from "@/helpers/formatter";
import { getOrderStatus } from "@/helpers/order-status";
import PreOrderSettings from "@/views/Products/shared/components/PreOrderSettings.vue";
import ProductModificators from "@/views/Products/shared/components/ProductModificators.vue";

export default {
  name: "Product",
  components: { ProductModificators, PreOrderSettings },
  data: () => ({
    average_price: null,
    product: {
      name: null,
      brand: null,
      category_id: null,
      saturday: false,
      status: 0,
      guarantee_period: null,
      pp: null,
      shop_id: 2,
    },
    categories: [],
    modifications: [],
    storage: [],
    shops: [],
    accountings: [],
    problem_accounting_ids: [],
    modification: { sku: null, sku_kaspi: null },
    loading: false,
    dialogVisible: false,
  }),
  computed: {
    product_id() {
      return this.$route.params.id;
    },
    user() {
      return this.$store.state.user;
    },
  },
  async created() {
    this.categories = await getCategories();
    this.shops = await getShops();
    if (this.product_id !== "new") {
      this.loading = true;
      const serverData = await getProduct(this.product_id);
      const {
        name,
        brand,
        category_id,
        saturday,
        status,
        guarantee_period,
        pp,
        shop_id,
      } = serverData.product;
      this.product = {
        name,
        brand,
        category_id,
        saturday,
        status,
        guarantee_period,
        pp,
        shop_id,
      };
      this.storage = serverData.storage;
      this.average_price = serverData.average_price;
      this.accountings = serverData.accountings;
      this.problem_accounting_ids = serverData.problem_accounting_ids;
      this.loading = false;
    }
  },
  methods: {
    async saveProduct() {
      if (this.product_id === "new") {
        await this.createProduct();
      } else {
        await this.updateProduct();
      }
    },

    async deleteProduct() {
      try {
        this.loading = true;
        const result = await deleteProduct(this.product_id);
        if (result.success) {
          this.$message.success("Успешно");
          this.$router.back();
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
      this.loading = false;
    },

    async createProduct() {
      try {
        this.loading = true;
        const result = await createProduct({ ...this.product });
        if (result.success) {
          this.$message.success("Успешно");
          await this.$router.replace({ params: { id: result.product_id } });
          setTimeout(() => {
            location.reload();
          }, 1000);
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
      this.loading = false;
    },

    async updateProduct() {
      try {
        this.loading = true;
        const result = await updateProduct(this.product_id, {
          ...this.product,
        });
        if (result.success) {
          this.$message.success("Успешно");
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
      this.loading = false;
    },

    dateTimeFormat(timestamp) {
      return dateTimeFormat(timestamp * 1000);
    },
    getStateOfAccounting(acc) {
      return getOrderStatus(acc);
    },
  },
};
</script>

<style scoped></style>
