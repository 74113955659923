<script>
import {
  createProductModification,
  deleteProductModification,
  getProductModifications,
} from "@/api/product";

import { getChannels } from "@/api/accounting";

export default {
  name: "ProductModificators",
  data() {
    return {
      loading: false,
      modifications: [],
      modification: { channel_id: null, sku: null, sku_kaspi: null },

      channels: [],
    };
  },
  computed: {
    product_id() {
      return this.$route.params.id;
    },
  },
  async mounted() {
    await this.getModifications();
    getChannels().then((res) => {
      this.channels = res;
    });
  },
  methods: {
    async getModifications() {
      this.modifications = await getProductModifications(this.product_id);
    },
    async createModification() {
      try {
        this.loading = true;
        const result = await createProductModification(
          this.product_id,
          this.modification
        );
        if (result.success) {
          this.$message.success("Успешно");
          this.modification = { sku: null, sku_kaspi: null };
          await this.getModifications();
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
      this.loading = false;
    },
    async deleteModification(id) {
      try {
        this.loading = true;
        const result = await deleteProductModification(this.product_id, id);
        if (result.success) {
          this.$message.success("Успешно");
          await this.getModifications();
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
      this.loading = false;
    },
    getChannelName(channelId) {
      const channel = this.channels.find((c) => c.id === channelId);
      return (channel && channel.name) || channelId;
    },
  },
};
</script>

<template>
  <div>
    <h1 class="font-semibold text-xl mt-5 mb-2 flex justify-between flex-col">
      <div class="mb-3">Модификаторы</div>
      <div class="flex">
        <el-select
          v-model="modification.channel_id"
          placeholder="Канал"
          size="small"
        >
          <el-option
            v-for="c in channels"
            :key="c.id"
            :label="c.name"
            :value="c.id"
          ></el-option>
        </el-select>
        <el-input
          v-model="modification.sku"
          placeholder="SKU"
          size="small"
          class="mr-2 ml-2"
        ></el-input>
        <el-input
          v-model="modification.sku_kaspi"
          placeholder="SKU Kaspi"
          size="small"
          class="mr-2"
        ></el-input>
        <el-button
          @click="createModification"
          :disabled="loading"
          icon="el-icon-plus"
          plain
          round
          type="primary"
          size="small"
        >
          Добавить
        </el-button>
      </div>
    </h1>
    <el-table border size="small" :data="modifications">
      <el-table-column type="index" label="N"></el-table-column>
      <el-table-column prop="sku" label="SKU"></el-table-column>
      <el-table-column prop="kaspi_sku" label="SKU kaspi"></el-table-column>
      <el-table-column align="right" prop="channel_id" label="Канал">
        <template slot-scope="scope">
          {{ getChannelName(scope.row.channel_id) }}
        </template>
      </el-table-column>
      <el-table-column align="right" prop="brand" label="Действия">
        <template slot-scope="scope">
          <el-button
            @click.stop="deleteModification(scope.row.id)"
            size="small"
            plain
            icon="el-icon-delete"
            type="danger"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<style scoped></style>
